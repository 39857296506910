import {Link} from "react-router-dom";
import React from 'react';
import {Navigate} from "react-router-dom";
import SearchResults from './SearchResults';
import {useAuth} from '../contexts/AuthContext';
import HeroImg from '../images/pexels-anna-shvets.jpg';


export default function Hero(){
    const {currentUser} = useAuth()

    return !currentUser ? (
        <div>
            <div className="hero_section">
                <div className="hero_column">
                    <h2>A support group for people who wet their plants.</h2>
                    <Link to='/signup'><div><button>Share your story!</button></div></Link>
                </div>
                <div className="hero_column face">
                    <img src={HeroImg} alt="Someone hiding in a snake plant."/>
                </div>
            </div>
            <SearchResults />
        </div>
    ): <Navigate to="/browse" />;
};

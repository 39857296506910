import React from 'react';
import ReactDOM from 'react-dom/client';
import Shop from './components/Shop.jsx';
import Hero from './components/Hero.jsx';
import Terms from './components/Terms.jsx';
import "react-image-crop/dist/ReactCrop.css";
import Header from './components/Header.jsx';
import Browse from './components/Browse.jsx';
import Footer from './components/Footer.jsx';
import Signup from './components/Signup.jsx';
import Privacy from './components/Privacy.jsx';
import Conduct from './components/Conduct.jsx';
import Dashboard from './components/Dashboard.jsx';
import JournalEdit from './components/JournalEdit.jsx';
import JournalView from './components/JournalView.jsx';
import { AuthProvider } from './contexts/AuthContext.js';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <Router>
      <AuthProvider>
        <Header />
        <Routes>
          <Route exact path="/" Component={Hero} />
          <Route path="/signup" Component={Signup} />
          <Route path="/dashboard" Component={Dashboard} />
          <Route path="/privacy" Component={Privacy} />
          <Route path="/browse" Component={Browse} />
          <Route path='/journal' Component={JournalEdit} />
          <Route path='/journals/:userId' Component={JournalView} /> 
          <Route path='/shop' Component={Shop} />
          <Route path='/terms' Component={Terms} />
          <Route path='/conduct' Component={Conduct} />
        </Routes>
        <Footer />
      </AuthProvider>
    </Router>
  // </React.StrictMode>
);


import React from 'react';
import {Navigate} from "react-router-dom";
import {useAuth} from '../contexts/AuthContext'

import Settings from './Settings'
import Activity from './Activity'


export default function Dashboard(){
    const {currentUser} = useAuth();

    return currentUser ? (
        <div className="dashboard">
            <Settings />
            <Activity />
        </div>
    ) : <Navigate to="/signup" />
};

const ForwardIcon = ({width}) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 -960 960 960" 
    width={width}
    fill="currentColor"
    >
    <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z"/>
  </svg>
  );
  
  export default ForwardIcon;
import { db } from '../firebase';
import Modal from "./crop/Modal";
import {useAuth} from '../contexts/AuthContext';
import React, {useState, useRef, useEffect} from 'react';
import { getAuth, reauthenticateWithCredential, deleteUser, updatePassword, EmailAuthProvider } from "firebase/auth";
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import { doc, getDoc, setDoc, collection, query, where, getDocs, deleteDoc } from "firebase/firestore";
import {Link, useNavigate, Navigate} from "react-router-dom";

import SaveIcon from "./icons/SaveIcon";
import LogoutIcon from "./icons/LogoutIcon";
import CameraIcon from "./icons/CameraIcon";
import ProfileIcon from "./icons/ProfileIcon";
import GarbageIcon from "./icons/GarbageIcon";
import SettingsIcon from "./icons/SettingsIcon";
import LandscapeIcon from "./icons/LandscapeIcon";


export default function Settings(){
    const navigate = useNavigate();
    const {currentUser, logout, updateEmail} = useAuth();
    
    const [error, setError] = useState('');
    const [hidden, setHidden] = useState(true);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    
    const emailRef = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const usernameRef = useRef();
    const passwordRef = useRef();
    const avatarUrl = useRef('');
    const primaryUrl = useRef('');
    const oldPasswordRef = useRef('');
    const passwordConfirmRef = useRef();
    const loginType = useRef();

    let auth = getAuth();
    let user = auth.currentUser;
    const deleteRef = useRef(null);
    const deleteConfirm = useRef('');
    const reauthConfirm = useRef(false);
    const {googleLogIn} = useAuth()

    async function handleLogout(){
        setError('');
        try {
            await logout();
            navigate('/');
        } catch {
            setError("Somehow failed to log out??");
        };
    };

    async function recentLoginCheck() {
        if (loginType.current==='email'){
            const credential = EmailAuthProvider.credential(
                user.email,
                oldPasswordRef.current
            )
            //const result = 
            await reauthenticateWithCredential(user, credential).then(() => {
                console.log("Email reauthentication complete.")
                reauthConfirm.current = true;
              }).catch((e) => {
                console.log(e);
                setError("Email reauthentication failed.");
                setModalOpen(false);
              });
        } else if (loginType.current==='google'){
            // const credential = GoogleAuthProvider.credential()
            await googleLogIn().then(() => {
            // await reauthenticateWithCredential(user, credential).then(() => {
                console.log("Google reauthentication complete.")
                auth = getAuth();
                user = auth.currentUser;
                reauthConfirm.current = true;
            }).catch((e) => {
                console.log(e);
                setError("Google reauthentication failed.");
                setModalOpen(false);
              });
        };
    }

    async function handlePassUpdate(e){
        e.preventDefault();
        await recentLoginCheck();
        if (passwordRef.current.value !== passwordConfirmRef.current.value){
            console.log("passwords didn't match.");
            return setError("Whoopsie, your passwords don't match.");
        } else if (passwordRef.current.value.length < 6){
            console.log("passwords too short.")
            return setError("Your passwod needs to have at least six characters.")
        } else if (passwordRef.current.value){
            setError("You're trying to update a password...");
            try{
                console.log("trying to update password.");
                setError('');
                setLoading(true);
                await updatePassword(user, passwordRef.current.value);
                setError("Password successfully updated.");
            } catch(err) {
                console.log("caught error updating password:");
                console.log(err);
                setError("Oops, we couldn't update your password.");
            }
            setLoading(false);
        };
    };

    async function handleEmailUpdate(){
        if (emailRef.current.value !== currentUser.email){
            try{
                setError('');
                setLoading(true) ;
                await updateEmail(emailRef.current.value);
                setError("An email with additional instructions has been sent.");
            } catch {
                setError("Oops, we couldn't update your email address.");
            }
            setLoading(false);
        };
    };

    const [cityState, setCityState] = useState('');
    async function handleCityUpdate(){
        if (cityRef.current.value !== cityState){
            try{
                setError('')
                setLoading(true)
                await setDoc(doc(db, "users", currentUser.uid), {city: `${cityRef.current.value}`}, {merge:true})
                setCityState(cityRef.current.value)
                setError("City updated.")
            } catch {
                setError("Oops, city failed to update.")
            }
            setLoading(false)
        }
    }

    const [stateState, setStateState] = useState('');
    async function handleStateUpdate(){
        if (stateRef.current.value !== stateState){
            try{
                setError('')
                setLoading(true)
                await setDoc(doc(db, "users", currentUser.uid), {state: `${stateRef.current.value}`}, {merge:true})
                setStateState(stateRef.current.value)
                setError("State code updated.")
            } catch {
                setError("Oops, state code failed to update.")
            }
            setLoading(false)
        }
    }

    const [usernameState, setUsernameState] = useState('')
    async function handleUsernameUpdate(){
        if (usernameRef.current.value !== usernameState){
            try{
                setError('')
                setLoading(true)
                await setDoc(doc(db, "users", currentUser.uid), {displayName: `${usernameRef.current.value}`}, {merge:true})
                setUsernameState(usernameRef.current.value)
                setError("User name updated.")
            } catch {
                setError("Oops, user name failed to update.")
            }
            setLoading(false)
        }
    }

    useEffect(()=> {
        if (!currentUser.uid) {
            return <Navigate to="/signup" />
        }

        const userProfileRef = doc(db, "users", currentUser.uid);
        const getUserProfile = async () => {
            try {
                const data = await getDoc(userProfileRef);
                setCityState(data.data().city);
                setStateState(data.data().state);
                setUsernameState(data.data().displayName)
                setImagesLoaded(true)
                primaryUrl.current = data.data().primaryURL;
                avatarUrl.current = data.data().photoURL;
                loginType.current = data.data().loginType;
            } catch(err){
                console.error(err);
            }
        };
        getUserProfile();
    }, [currentUser.uid]);

    const updateAvatar = (imgSrc) => {
        avatarUrl.current = imgSrc;
    };

    function toggleHidden() {
        hidden===true ? setHidden(false) : setHidden(true);
    }

    function saveClose() {
        handleUsernameUpdate();
        handleEmailUpdate();
        handleCityUpdate();
        handleStateUpdate();
        toggleHidden();
    }

    const deleteProfile = async (e) =>{
        e.preventDefault();
        await recentLoginCheck()
        if (deleteConfirm.current === 'Delete' && reauthConfirm.current) {
            //First we delete this user's messages on all boards.
            const messageList = collection(db, "messages");
            const thisUserMessages = query(
                messageList,
                where("userID", "==", currentUser.uid),
            );
            const deleteThisUserMessages = async () => {
                let data = await getDocs(thisUserMessages);
                data.forEach(async(message) => {
                    console.log(`Message to be deleted from other boards: ${message.id}`)
                    await deleteDoc(doc(db, "messages", message.id));
                })
            };
            deleteThisUserMessages()

            //Second we create a list of this user's updates.
            const boardList = collection(db, "updates");
            const userBoards = query(
                boardList,
                where("user", "==", currentUser.uid)
            );

            let boards = await getDocs(userBoards);
            boards.forEach(async(board) => {
                //So we can delete other users' messages on this user's boards.
                let otherUserMessages = query(
                    messageList,
                    where("board", "==", board.id)
                );
                let messages = await getDocs(otherUserMessages);
                messages.forEach(async(message) => {
                    console.log(`Message to be deleted from other users: ${message.id}`)
                    await deleteDoc(doc(db, "messages", message.id));
                })
                //Then we delete this user's boards.
                console.log(`Deleting user's board: ${board.id}`)
                await deleteDoc(doc(db, "updates", board.id));
            })

            //Then we delete other user messages on the user's main post.
            let otherUserMessages = query(
                messageList,
                where("board", "==", currentUser.uid)
            );
            let messages = await getDocs(otherUserMessages);
            messages.forEach(async(message) => {
                console.log(`Message to be deleted from main post: ${message.id}`)
                await deleteDoc(doc(db, "messages", message.id));
            })

            //Then we delete this user's saved images.
            const storage = getStorage();
            const folderRef = ref(storage, currentUser.uid);
            listAll(folderRef)
            .then((res) => {
                res.items.forEach((itemRef) => {
                    console.log(`deleting item: ${itemRef.fullPath}`);
                    deleteObject(itemRef);
                });
            });

            //Then we delete this user's profile.
            console.log(`Deleting user profile: ${currentUser.uid}`)
            await deleteDoc(doc(db, "users", currentUser.uid));

            //Then we delete this user's authentication.
            console.log(`Deleting user: ${user}`)
            await deleteUser(user)

            //Finally we close the modal and return the user to the home page.
            deleteRef.current?.close();
        }
    }

    function closeDelete() {
        deleteRef.current?.close();
    } 

    return currentUser ? (
        <div className="profile">
            {!imagesLoaded ? <LandscapeIcon width="240px"/> : 
                <img
                src={primaryUrl.current}
                alt="User's collection overview"
                className="primary"
                />
            }

            {!imagesLoaded ? <ProfileIcon width="24px"/> :
                <img
                src={avatarUrl.current}
                alt="User's avatar"
                className="avatar"
                />
            }

            <button
            hidden={hidden}
            className="avatar_button"
            title="Change photo"
            onClick={() => setModalOpen(true)}
            >
            <CameraIcon width="24px"/> Update Profile Pic
            </button>

            {modalOpen && (
                <Modal
                updateAvatar={updateAvatar}
                closeModal={() => setModalOpen(false)}
                modalType = 'profile'
                />
            )}

            <h1 hidden={!hidden}>Welcome {usernameState}! </h1>
            {error && <div className="signup-alert-box">{error}</div>}

            {loginType.current==="email" && !hidden && (
                <form className='profile'>
                    <div>
                        <input type="email" name="email" defaultValue={currentUser.email} autoComplete="email" style={{display: "none"}}/>
                        <label htmlFor="password">Old password:</label>
                        <input type="password" name="oldPassword" id="oldPassword" autoComplete="current-password" required="" onInput={e => oldPasswordRef.current = e.target.value}/>
                    </div>
                    <div>
                        <label htmlFor="password">New password:</label>
                        <input type="password" name="newPassword" id="newPassword" autoComplete="new-password" ref={passwordRef}/>
                    </div>
                    <div>
                        <label htmlFor="confirm-password">Confirm new password:</label>
                        <input type="password" name="confirmPassword" id="confirmPassword" autoComplete="new-password" ref={passwordConfirmRef}/>
                    </div>
                    <button type="passwordUpdate" onClick={handlePassUpdate} disabled={loading}>Update Password</button>
                </form>
            )}

            <div hidden={hidden}>
                <label htmlFor="username">New user name: </label>
                <input type="username" name="username" id="username" ref={usernameRef} defaultValue={usernameState}/>
            </div>

            <h4 hidden={!hidden}>Email: {currentUser.email}</h4>
            <div hidden={hidden}>
                <label htmlFor="email">New email: </label>
                <input type="email" name="email" id="email" ref={emailRef} defaultValue={currentUser.email}/>
            </div>

            <h4 hidden={!hidden}>Location: {cityState}, {stateState}</h4>
            <div hidden={hidden}>
                <label htmlFor="city">New city: </label>
                <input type="city" name="city" id="city" ref={cityRef} defaultValue={cityState}/>
            </div>

            <div hidden={hidden}>
                <label htmlFor="state">New state: </label>
                <input type="state" name="state" id="state" ref={stateRef} defaultValue={stateState}/>
            </div>

            <button onClick={toggleHidden} hidden={!hidden} disabled={loading}><SettingsIcon width="24px"/>Settings</button>

            <div>
                <button onClick={saveClose} hidden={hidden} disabled={loading} style={{margin: "10px"}}><SaveIcon width="24px"/>Save & Close</button>
                <button onClick={() => deleteRef.current?.showModal()} className="deleteAccount" hidden={hidden} disabled={loading}><GarbageIcon width="24px"/>Delete Account</button>
            </div>          
            <Link to="/" hidden={!hidden}>
                <button onClick={handleLogout} disabled={loading}><LogoutIcon width="24px"/> Log Out</button>
            </Link>

            <dialog ref={deleteRef} className='deleteBox'>
                    

                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                        <p>Type "Delete" below to permanently delete your account, profile, updates, pictures, and comments. Your account CANNOT be recovered after this:</p>
                        <input type="text" name="delete" id="delete" required="" defaultValue="Type Delete here." onInput={e => deleteConfirm.current = e.target.value}/>
                    </div>
                    
                    {loginType.current==='email' &&
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                            <p>Also, please confirm your password:</p>
                            <input type="password" name="oldPassword" id="oldPassword" autoComplete="current-password" required="" onInput={e => oldPasswordRef.current = e.target.value}/>
                        </div>
                    }
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                        <button id="closeDialog" onClick={closeDelete} style={{}}>Exit</button>
                        <button id="confirmDialog" className="deleteAccount" onClick={deleteProfile}>Delete Account</button>
                    </div>
                    
            </dialog>
        </div>
    ) : <Navigate to="/signup" />
}
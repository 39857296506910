import React, { useState, useEffect } from 'react';
import {useAuth} from '../contexts/AuthContext'
import {NavLink} from "react-router-dom";


export default function Navbar(){
    const {currentUser} = useAuth()
    
    function showSidebar(){
        if (currentUser) {
            const sidebar = document.querySelector('.sidebar');
            sidebar.style.display = 'unset'
        }
    }

    function hideSidebar(){
        if (currentUser) {
            const sidebar = document.querySelector('.sidebar');
            sidebar.style.display = 'none'
        } 
    }

    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 750;

    useEffect(() => {
     const handleResizeWindow = () => setWidth(window.innerWidth);
      // subscribe to window resize event "onComponentDidMount"
      window.addEventListener("resize", handleResizeWindow);
      
      if (width > breakpoint) {
        hideSidebar();
      }

      return () => {
        // unsubscribe "onComponentDestroy"
        window.removeEventListener("resize", handleResizeWindow);
      };
    }, [width]);

 
    return currentUser ? (
        <div>
            <div className='headerbar nav'>
                <NavLink to="/browse">
                    <h2>Browse</h2>
                </NavLink>

                <NavLink to="/journal">
                    <h2>Journal</h2>
                </NavLink>

                <NavLink to="/shop">
                    <h2>Shop</h2>
                </NavLink>

                <NavLink to="/dashboard">
                    <h2>Dashboard</h2>
                </NavLink>
                
                <svg onClick={showSidebar} className='hamburger' xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="currentColor"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/></svg>
                
            </div>
            <div className='sidebar nav'>
                <svg onClick={hideSidebar} className='close' xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="currentColor"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
            
                <NavLink to="/browse">
                    <h2>Browse</h2>
                </NavLink>

                <NavLink to="/journal">
                    <h2>Journal</h2>
                </NavLink>

                <NavLink to="/shop">
                    <h2>Shop</h2>
                </NavLink>

                <NavLink to="/dashboard">
                    <h2>Dashboard</h2>
                </NavLink>
            </div>
        </div>
    )
    : (
        <div>
            <NavLink to="/signup">
                <button>Log In</button>
            </NavLink>
        </div>
    );
}


import React, {useState, useEffect, useRef} from 'react';
import { collection, query, orderBy, getDocs, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from '../firebase'
import HeartEmptyIcon from "./icons/HeartEmptyIcon";
import HeartFilledIcon from "./icons/HeartFilledIcon";
import { useAuth } from '../contexts/AuthContext'


export default function SearchResults(){
    const border = {borderRadius: "7px 7px 0px 0px"};
    const color = {color: "var(--green)"};

    const {currentUser} = useAuth();
    const journalList = collection(db, "users");
    const [allJournals, setAllJournals] = useState([])
    useEffect(() => {
        let journals = [];
        
        const queryJournals = query(
            journalList,
            orderBy("recentUpdate", "desc"),
        );
        const fetchJournals = async () => {
            let data = await getDocs(queryJournals);
            data.forEach((doc) => {
                journals.push({ID: doc.id, ...doc.data() });
            })
            setAllJournals(journals)
        };
        fetchJournals();
    },[]);

    const [likeListState, setLikeListState] = useState([]);
    const likeListRef = useRef([]);

    async function createBlankLikeList(){
        await setDoc(doc(db, "users", currentUser.uid), {likeList: []}, {merge:true})
    }

    //This gets the initial likeList data.
    useEffect(() => {
        if (currentUser){
            const queryLikes = doc(db, 'users', currentUser.uid);
            const getUserLikelist = async () => {
                try {
                    const data = await getDoc(queryLikes);
                    if(data.data().likeList){
                        likeListRef.current = (data.data().likeList);
                    } else {
                        createBlankLikeList()
                    }
                } catch(e) {
                    console.log(e);
                };
            };
            getUserLikelist();
        };
    },[likeListState]);

    //This updates the likeList data on button click.
    async function toggleLike(journal, e) {
        e.preventDefault()
        if(likeListRef.current.includes(journal)){
            //If the journal is on the list, delete it and update the list.
            likeListRef.current = likeListRef.current.filter((item) => item !== journal)
        } else {
            //If the journal isn't on the list, add it and update the list.
            likeListRef.current.push(journal)
        }
        await setDoc(doc(db, "users", currentUser.uid), {likeList: likeListRef.current}, {merge:true})
        setLikeListState(likeListRef.current);
    }

    return (
        <div className="browse">
            <div className='search-page'>
                <div className="search-bar">
                    <h2 style={border}>Browse Journals</h2>
                </div>
                <div className='search-results'>
                    {allJournals.map((journal) =>
                        <div className='search-result'>
                            {currentUser && <button onClick={(e) => toggleLike(journal.ID, e)} className="likeButton">
                                {likeListRef.current.includes(journal.ID) ? <HeartFilledIcon width="40px"/> : <HeartEmptyIcon width="40px"/>}
                                
                            </button>}
                            <a href={`journals/${journal.ID}`}><img
                                src={journal.primaryURL}
                                alt="Link to a user's journal"
                                className='search-result-image'
                                />
                            </a>
                            <div className='search-result-info'>
                                <div className='search-result-name'>
                                    <h3>Plants Wetter</h3>
                                    <h3 style={color}>{journal.displayName}</h3>
                                </div>
                                <div className='search-result-location'>
                                    <h3>{journal.city}, {journal.state}</h3>
                                </div>
                                
                            </div>
                        </div>
                        
                    )}
                </div>
            </div>
        </div>
    )
};

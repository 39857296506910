import React from 'react';


export default function Terms(){
    return (
        <div className='legal'>
            <h1>Terms & Conditions</h1>
            <p>Last Revised August 15, 2024</p>

            <p>Greetings! </p>

            <p>This PlantsWetter User Agreement (“Terms”) applies to your access to and use of the websites and other online products and services (collectively, the “Services”) provided by PlantsWetter, (“PlantsWetter,” “we,” “us,” or “our”).</p>

            <p>PlantsWetter is for fun, education, and entertainment. By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use our Services.</p>
            <h2>1. Your Access to the Services</h2>
            <p>By using the Services, you state that:</p>
            <ul>
                <li>You are at least 18 years old and over the minimum age required by the laws of your country of residence to access and use the Services;</li>
                <li>You can form a binding contract with PlantsWetter;</li>
                <li>You are not barred from using the Services under all applicable laws; and</li>
                <li>You have not been permanently suspended or removed from the Services.</li>
            </ul>
            <p>If you are accepting these Terms on behalf of another legal entity, including a business or government entity, you represent that you have full legal authority to bind such entity to these Terms.</p>
            <h2>2. Privacy</h2>
            <p>PlantsWetter's <a href="/privacy">Privacy Policy</a> explains how and why we collect, use, and share information about you when you access or use our Services. You understand that through your use of the Services, you consent to the collection and use of this information as set forth in the <a href="/privacy">Privacy Policy</a>.</p>
            <h2>3. Your Use of the Services</h2>
            <p>Subject to your complete and ongoing compliance with these Terms, PlantsWetter grants you a personal, non-transferable, non-exclusive, revocable, limited license to access and use the Services. We reserve all rights not expressly granted to you by these Terms.</p>

            <p>Except and solely to the extent such a restriction is impermissible under applicable law, you may not, without our written agreement:</p>
            
            <ul>
                <li>license, sell, transfer, assign, distribute, host, or otherwise commercially exploit the Services or Content; or,</li>
                <li>modify, prepare derivative works of, disassemble, decompile, or reverse engineer any part of the Services or Content.</li>
            </ul>

            <p>We are always improving our Services. This means we may add or remove features, products, or functionalities; we will try to notify you beforehand, but that won't always be possible. We reserve the right to modify, suspend, or discontinue the Services (in whole or in part) at any time, with or without notice to you. Any future release, update, or other addition to functionality of the Services will be subject to these Terms, which may be updated from time to time. You agree that we will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Services or any part thereof.</p>

            <p>We personalize your experience on the Services based on a variety of factors, including your activity on the Services and preferences you've identified in your settings. Based on these and other factors, we may make recommendations to make your experience on PlantsWetter more relevant. </p>
            <h2>4. Your PlantsWetter Account and Account Security</h2>
            <p>To use certain features of our Services, you may be required to create a PlantsWetter account (an “Account”) and provide us with a username, password, and certain other information about yourself as set forth in the <a href="/privacy">Privacy Policy</a>.</p>

            <p>You are solely responsible for the information associated with your Account and anything that happens related to your Account. You must maintain the security of your Account and immediately notify PlantsWetter if you discover or suspect that someone has accessed your Account without your permission. We recommend that you use a strong password that is used only with your Account.</p>

            <p>You will not license, sell, or transfer your Account without our prior written approval.</p>
            <h2>5. Your Content</h2>
            <p>The Services may contain information, text, links, graphics, photos, or other materials (“Content”), including Content created with or submitted to the Services by you or through your Account (“Your Content”). We take no responsibility for and we do not expressly or implicitly endorse, support, or guarantee the completeness, truthfulness, accuracy, or reliability of any of Your Content.</p>

            <p>By submitting Your Content to the Services, you represent and warrant that you have all rights, power, and authority necessary to grant the rights to Your Content contained within these Terms. Because you alone are responsible for Your Content, you may expose yourself to liability if you post or share Content without all necessary rights.</p>

            <p>You retain any ownership rights you have in Your Content, but you grant PlantsWetter the following license to use that Content:</p>

            <p>When Your Content is created with or submitted to the Services, you grant us a worldwide, royalty-free, perpetual, irrevocable, non-exclusive, transferable, and sublicensable license to use, copy, modify, adapt, prepare derivative works of, distribute, store, perform, and display Your Content and any name or username provided in connection with Your Content in all media formats and channels now known or later developed anywhere in the world. This license includes the right for us to make Your Content available for syndication, broadcast, distribution, or publication by other companies, organizations, or individuals who partner with PlantsWetter. You also agree that we may remove metadata associated with Your Content, and you irrevocably waive any claims and assertions of moral rights or attribution with respect to Your Content.</p>

            <p>Any ideas, suggestions, and feedback about PlantsWetter or our Services that you provide to us are entirely voluntary, and you agree that PlantsWetter may use such ideas, suggestions, and feedback without compensation or obligation to you.</p>

            <p>Although we have no obligation to screen, edit, or monitor Your Content, we may, in our sole discretion, delete or remove Your Content, at any time and for any reason, including for violating these Terms, our <a href="/conduct">Code of Conduct</a>, or if you otherwise create or are likely to create liability for us.</p>
            <h2>6. Third-Party Content, Advertisements, and Promotions</h2>
            <p>The Services may contain links to third-party websites, products, or services, which may be posted by advertisers, our affiliates, our partners, or other users (“Third-Party Content”). Third-Party Content is not under our control, and we are not responsible for any third party's websites, products, or services. Your use of Third-Party Content is at your own risk and you should make any investigation you feel necessary before proceeding with any transaction in connection with such Third-Party Content.</p>

            <p>The Services may also contain sponsored Third-Party Content or advertisements. The type, degree, and targeting of advertisements are subject to change, and you acknowledge and agree that we may place advertisements in connection with the display of any Content or information on the Services, including Your Content.</p>

            <p>It is a violation of our <a href="/conduct">Code of Conduct</a> to use the Services to conduct a promotion, including a contest or sweepstakes (“Promotion”). Conducting the Promotion requires compliance with all applicable laws and regulations, including creating official rules, offer terms, eligibility requirements, and compliance with applicable laws, rules, and regulations which govern the Promotion (such as licenses, registrations, bonds, and regulatory approval). If you violate our <a href="/conduct">Code of Conduct</a> and post a Promotion that we are unaware of and/or are unable to remove before it's detected, you acknowledge and agree that we will not assist you in any way with your Promotion.</p>
            <h2>7. Things You Cannot Do</h2>
            <p>When using or accessing the Services, you must comply with these Terms and all applicable laws, rules, and regulations. Please review the <a href="/conduct">Code of Conduct</a>, which is incorporated by this reference into, and made a part of, these Terms and contain PlantsWetter's rules about prohibited content and conduct. In addition to what is prohibited in the <a href="/conduct">Code of Conduct</a>, you may not do any of the following:</p>
            <ul>
                <li>Use the Services in any manner that could interfere with, disable, disrupt, overburden, or otherwise impair the Services;</li>
                <li>Gain access to (or attempt to gain access to) another user's Account or any non-public portions of the Services, including the computer systems or networks connected to or used together with the Services;</li>
                <li>Upload, transmit, or distribute to or through the Services any viruses, worms, malicious code, or other software intended to interfere with the Services, including its security-related features;</li>
                <li>Use the Services to violate applicable law or infringe any person's or entity's intellectual property rights or any other proprietary rights;</li>
                <li>Access, search, or collect data from the Services by any means (automated or otherwise) except as permitted in these Terms or in a separate agreement with PlantsWetter (we conditionally grant permission to crawl the Services in accordance with the parameters set forth in our robots.txt file, but scraping the Services without PlantsWetter's prior written consent is prohibited); or</li>
                <li>Use the Services in any manner that we reasonably believe to be an abuse of or fraud on PlantsWetter.</li>
            </ul>
            <p>We encourage you to report content or conduct that you believe violates these Terms or our <a href="/conduct">Code of Conduct</a>. We also support the responsible reporting of security vulnerabilities. To report a security issue, please email <a href="mailto:hello@plantswetter.com">hello@plantswetter.com</a>.</p>
            <h2>8. Copyright, Trademark, the DMCA, and Takedowns</h2>
            <p>PlantsWetter respects the intellectual property of others and requires that users of our Services do the same. We have a policy that includes the removal of any infringing material from the Services and for the termination, in appropriate circumstances, of users of our Services who are repeat infringers. If you believe that anything on our Services infringes a copyright or a trademark that you own or control, you may notify PlantsWetter's Designated Agent by filling out our Copyright Report Form or Trademark Report Form, or by contacting:</p>

            <p>
            Angels Take Inc. ℅ DMCA Compliance<br/>
            693 Union St. #1L<br/>
            Brooklyn, NY 11215<br/>
            <a href="mailto:hello@plantswetter.com">hello@plantswetter.com</a><br/>
            </p>

            <p>See 17 U.S.C. § 512(c)(3) for the requirements of a proper notification. Also, please note that if you knowingly misrepresent that any activity or material on our Services is infringing, you may be liable to PlantsWetter for certain costs and damages.</p>

            <p>If we remove Your Content in response to a copyright or trademark notice, we will notify you via email. If you believe Your Content was wrongly removed due to a mistake or misidentification in a copyright notice, you can send a counter notification via email or to our Copyright Agent (contact information provided above). Please see 17 U.S.C. § 512(g)(3) for the requirements of a proper counter notification.</p>
            <h2>9. Intellectual Property</h2>
            <p>The Services are owned and operated by PlantsWetter. The visual interfaces, graphics, design, compilation, information, data, computer code, products, services, trademarks, and all other elements of the Services (“Materials”) provided by PlantsWetter are protected by intellectual property and other laws. All Materials included in the Services are the property of PlantsWetter or its third-party licensors. You acknowledge and agree that you shall not acquire any ownership rights whatsoever by downloading Materials or by purchasing Paid Services. Except as expressly authorized by PlantsWetter, you may not make use of the Materials. PlantsWetter reserves all rights to the Materials not granted expressly in these Terms.</p>
            <h2>10. Indemnity</h2>
            <p>Except to the extent prohibited by law, you agree to defend, indemnify, and hold PlantsWetter, its affiliates, and their respective, directors, officers, employees, affiliates, agents, contractors, third-party service providers, and licensors (the “PlantsWetter Entities”) harmless from and against any claim or demand made by any third party, and any related liability, damage, loss, and expense (including costs and attorneys' fees) due to, arising out of, or in connection with: (a) your use of the Services; (b) your violation of these Terms; (c) your violation of applicable laws or regulations; or (d) Your Content. We reserve the right to control the defense of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims.</p>
            <h2>11. Disclaimers and Limitation of Liability</h2>
            <p>Nothing in these Terms will prejudice the statutory rights that you may have as a user of the Services. Some countries, states, provinces or other jurisdictions do not allow the exclusion of certain warranties or the limitation of liability as stated in this section, so the below terms may not fully apply to you. Instead, in such jurisdictions, the exclusions and limitations below shall apply only to the extent permitted by the laws of such jurisdictions.</p>

            <p>THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, LEGAL, OR STATUTORY, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THE PLANTSWETTER ENTITIES DO NOT WARRANT THAT THE SERVICES ARE ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR FREE. PLANTSWETTER DOES NOT CONTROL, ENDORSE, OR TAKE RESPONSIBILITY FOR ANY CONTENT AVAILABLE ON OR LINKED TO THE SERVICES OR THE ACTIONS OF ANY THIRD PARTY OR USER, INCLUDING MODERATORS. WHILE PLANTSWETTER ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF OUR SERVICES SAFE, WE DO NOT REPRESENT OR WARRANT THAT OUR SERVICES OR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>

            <p>IN NO EVENT WILL ANY OF THE PLANTSWETTER ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, OR LOST PROFITS ARISING FROM OR RELATING TO THESE TERMS OR THE SERVICES, INCLUDING THOSE ARISING FROM OR RELATING TO CONTENT MADE AVAILABLE ON THE SERVICES THAT IS ALLEGED TO BE DEFAMATORY, OFFENSIVE, OR ILLEGAL. ACCESS TO, AND USE OF, THE SERVICES IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR RESULTING LOSS OF DATA. IN NO EVENT WILL THE AGGREGATE LIABILITY OF THE PLANTSWETTER ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS ($100) OR ANY AMOUNT YOU PAID PLANTSWETTER IN THE PREVIOUS SIX MONTHS FOR THE SERVICES GIVING RISE TO THE CLAIM. THE LIMITATIONS OF THIS SECTION WILL APPLY TO ANY THEORY OF LIABILITY, INCLUDING THOSE BASED ON WARRANTY, CONTRACT, STATUTE, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, EVEN IF THE PLANTSWETTER ENTITIES HAVE BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF ANY REMEDY SET FORTH IN THESE TERMS IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE.</p>
            <h2>12. Governing Law and Venue</h2>
            <p>We want you to enjoy PlantsWetter, so if you have an issue or dispute, you agree to raise it and try to resolve it with us informally. You can contact us with feedback and concerns via email.</p>

            <p>To the fullest extent permitted by applicable law, any claims arising out of or relating to these Terms or the Services will be governed by the laws of the State of New York, without regard to its conflict of laws rules; all disputes related to these Terms or the Services will be brought solely in the federal or state courts located in Brooklyn, New York, and you and PlantsWetter consent to personal jurisdiction in these courts.</p>

            <p>If you are a U.S. city, county, or state government entity, then this Section 14 does not apply to you. If you are a U.S. federal government entity, any claims arising out of or relating to these Terms or the Services will be governed by the laws of the United States of America without regard to its conflict of laws rules. To the extent permitted by federal law, the laws of New York (other than its conflict of law rules) will apply in the absence of applicable federal law. All disputes related to these Terms or the Services will be brought solely in the federal or state courts located in Brooklyn, New York.</p>
            <h2>13. Changes to these Terms</h2>
            <p>We may make changes to these Terms from time to time. If we make changes, we will post the revised Terms and update the Effective Date above. If the changes, in our sole discretion, are material, we may also notify you by sending an email to the address associated with your Account (if you have chosen to provide an email address) or by otherwise providing you with notice through our Services. By continuing to access or use the Services on or after the Effective Date of the revised Terms, you agree to be bound by the revised Terms. If you do not agree to the revised Terms, you must stop accessing and using our Services before the changes become effective.</p>
            <h2>14. Termination</h2>
            <p>You may terminate these Terms at any time and for any reason by deleting your Account and discontinuing use of all Services.</p>

            <p>To the fullest extent permitted by applicable law, we may suspend or terminate your Account or ability to access or use the Services at any time for any or no reason, including for violating these Terms or our <a href="/conduct">Code of Conduct</a>.</p>

            <p>The following sections will survive any termination of these Terms or of your Account: 5 (Your Content), 7 (Things You Cannot Do), 10 (Indemnity), 11 (Disclaimers and Limitation of Liability), 12 (Governing Law and Venue), 14 (Termination), and 15 (Miscellaneous).</p>
            <h2>15. Miscellaneous</h2>
            <p>These Terms, together with the <a href="/privacy">Privacy Policy</a> and any other agreements expressly incorporated by reference into these Terms, constitute the entire agreement between you and us regarding your access to and use of the Services. Our failure to exercise or enforce any right or provision of these Terms will not operate as a waiver of such right or provision. If any part of these Terms is held to be invalid or unenforceable, the unenforceable part will be given effect to the greatest extent possible, and the remaining parts will remain in full force and effect. You may not assign or transfer any of your rights or obligations under these Terms without our consent. We may freely assign any of our rights and obligations under these Terms.</p>

            <p>Headings are used in these Terms for reference only and will not be considered when interpreting them. For purposes of these Terms: (a) the words “include,” “includes,” and “including” will be deemed to be followed by the words “without limitation;” (b) the words “such as,” “for example,” “e.g.,” and any derivatives of those words will mean by way of example and the items that follow these words will not be deemed an exhaustive list; and (c) the word “or” is used in the inclusive sense of “and/or” and the terms “or,” “any,” and “either” are not exclusive. No ambiguity will be construed against any party based on a claim that the party drafted the language.</p>

            <p>These Terms are a legally-binding agreement between you and PlantsWetter, Inc. If you have any questions about these Terms, please contact us.</p>

        </div>
    );
}

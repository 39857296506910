import React from 'react';
import Logo from '../images/Logo.svg';
import {Link} from "react-router-dom";
import Navbar from "./Navbar.jsx";

function Header(){
    return (
    <div className='top_shell'>
        <div className='header'>
            <Link to="/">
                <div className='logo_component'>
                    <img src={Logo} height="40" width="auto" alt="PlantsWetter logo"/>
                    <div>
                        <h1>PlantsWetter</h1>
                        <h3 style={{marginTop: "-20px", padding: "5px"}}>Oops, I wet my plants again!</h3>
                    </div>
                </div>
            </Link>
            <Navbar />
        </div>
    </div>
    );
}

export default Header;

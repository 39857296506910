import React, {useState, useRef, useLayoutEffect} from 'react';
import {useCookies} from 'react-cookie';
import {Link} from "react-router-dom";

import Twitter from "./icons/twitterx.png";
import Facebook from "./icons/facebook.png";
import Instagram from "./icons/instagram.png";

export default function Footer(){
    const year = new Date().getFullYear();
    const [cookies, setCookie] = useCookies(["cookieConsent"]);
    const giveCookieConsent = () =>{
        setCookie("cookieConsent", true, {path:"/"});
    };

    const CookieConsent = () => {
        return (
          <div className="cookie-consent">
            <p>
              We use cookies to keep you logged in, to enhance your user experience, and to remember if you've clicked this button. 
              By using our website, you agree to our use of cookies. You can learn more in our privacy policy.
              <a href={"/privacy"}> Learn more.</a>
            </p>
            <button onClick={giveCookieConsent}>
              Accept
            </button>
          </div>
        );
      };

    const [bumperHeight, setBumperHeight] = useState("75px");
    const footerRef = useRef(null);
    useLayoutEffect(()=>{
      setBumperHeight(footerRef.current.scrollHeight)
    },[]);

    return (
      <div className='footer-bumper'
        style={{
          minHeight: "45px",
          height: bumperHeight
      }}>
        <div className='bottom_shell'style={{minHeight: "45px", height: bumperHeight}}>
          <div className='footer'
            ref={footerRef}>
            <div className='footer_component'>

                <p className>PlantsWetter.com {`© ${year}`} | Brooklyn, USA</p>
                <p className='footer_component'><Link to="/conduct">Code of Conduct</Link> | 
                <Link to="/terms"> Terms & Conditions </Link> | 
                <Link to="/privacy"> Privacy & Cookies</Link></p>

            </div>
              
            <div className='footer_component'>
              <a href="https://www.facebook.com/profile.php?id=61564880379707">
                <img src={Facebook} alt="facebok logo" width="30px"/>
              </a>
              <a href="https://www.instagram.com/wetplantsagain/">
                <img src={Instagram} alt="instagram logo" width="30px"/>
              </a>
              <a href="https://x.com/wetplantsagain">
                <img src={Twitter} alt="twitter logo" width="30px"/>
              </a>
            </div>
          </div>
            {/* {!cookies.cookieConsent && <CookieConsent />} */}
        </div>
      </div>
    );
}


import React, {useState, useRef, useEffect} from 'react';
import CameraIcon from "./icons/CameraIcon.jsx";
import Modal from "./crop/Modal.jsx";
import TextEditor from './textEditor/TextEditor.tsx';
import LandscapeIcon from "./icons/LandscapeIcon.jsx";
import Feedback from './Feedback.jsx'


export default function Entry({boardOwner, boardNumber, boardPicture, entryCollection, viewerType, newUpdateIncrementer}){
    const primaryUrl = useRef(boardPicture);
    const [modalOpen, setModalOpen] = useState(false);
    const [imageUrlLoaded, setImageUrlLoaded] = useState(false);

    // By default we assume that if the modal is opened it's changing the image of an update.
    // However, if the entryCollection is "users" (as opposed to "updates"), we reset modalType to 'primary'.
    let modalType = "update";
    if (entryCollection === 'users'){
        modalType = 'primary';
    };

    const updateAvatar = (imgSrc) => {
        primaryUrl.current = imgSrc;
    };

    useEffect(() => {
        if (boardPicture){
            primaryUrl.current = boardPicture;
            setImageUrlLoaded(true);
        }
    },[boardPicture]);

    const textHeightRef = useRef(null);
    const photoHeightRef = useRef(null);
    const [photoHeight, setPhotoHeight] = useState(0);
    const [textHeight, setTextHeight] = useState(0);
    const [totalHeight, setTotalHeight] = useState(0);

    useEffect(() => {
        setTotalHeight(photoHeight + textHeight)
    },[photoHeight, textHeight]);

    useEffect(() => {
        if (textHeightRef.current) {
            const observer = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    setTextHeight(entry.contentRect.height);
                }
            });

            observer.observe(textHeightRef.current);

            // Cleanup function
            return () => {
                observer.disconnect();
            };
        }
    }, [imageUrlLoaded]);

    useEffect(() => {
        if (photoHeightRef.current && imageUrlLoaded) {
            const observer = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    setPhotoHeight(entry.contentRect.height);
                }
            });

            observer.observe(photoHeightRef.current);

            // Cleanup function
            return () => {
                observer.disconnect();
            };
        }
    }, [imageUrlLoaded]);

    return(
        <div className='entry'>
            <div className='photo-wrap' ref={photoHeightRef}>
                {!imageUrlLoaded ? <LandscapeIcon width="735px"/> : 
                    <img
                    src={primaryUrl.current}
                    alt="User's collection overview"
                    className="collection"
                    />
                }

                {viewerType==='writer' && <button
                className="photo-edit-button"
                title="Change photo"
                onClick={() => setModalOpen(true)}
                >
                <CameraIcon width="34px"/>
                </button>}
            </div>
            {modalOpen && (
                <Modal
                updateAvatar={updateAvatar}
                closeModal={() => setModalOpen(false)}
                modalType = {modalType}
                boardNumber={boardNumber}
                newUpdateIncrementer={newUpdateIncrementer}
                />
            )}
            
            <Feedback 
                boardOwner={boardOwner}
                className="feedback" 
                board={boardNumber}
                journalHeight = {totalHeight}
            ></Feedback>
            
            <div className='textRuler' ref={textHeightRef}>
                <TextEditor 
                    readerLookup={boardNumber}
                    entryCollection={entryCollection} 
                    viewerType={viewerType}
                />
            </div>
        </div>
    )
};

import React from 'react';


export default function Conduct(){
    return (
        <div className='legal'>
            <p>To participate on PlantsWetter, you must know and follow our Code of Conduct:</p>
            <h2>Rule 1</h2>
            <p>Stay on topic. Our shared interest is houseplants. If what you're sharing or discussing is not houseplant related, it may be subject to removal.</p>
            <h2>Rule 2</h2>
            <p>Do not post about illegal substances. Yes marijuana can be a houseplant. No this is not the right place to share your stories about growing it. Posts about illegal substances, even if they're legal in your particular area, will be removed. This isn't a moral judgment, it's just off-topic.</p>
            <h2>Rule 3</h2>
            <p>Be kind. No harassment, threats, or bullying. Respect the privacy of others. </p>
            <h2>Rule 4</h2>
            <p>Don't be or behave like a bot. Don't spam content. </p>
            <h2>Rule 5</h2>
            <p>No sweepstakes or contests. Contests and sweepstakes are heavily regulated, not allowed on our website, and will be immediately removed if found. Please read our user agreement for more information on the personal risk you take if you attempt to post sweepstakes or contests. You may offer to sell a plant or give it away for free. </p>
            <h2>Rule 6</h2>
            <p>No self promotion. This isn't the place to direct people to your social media accounts, brand/business pages, personal blog etc.</p>
            <h2>Enforcement</h2>
            <p>We would prefer not to, but we have a variety of ways of enforcing our rules, including, but not limited to:</p>
            <ul>
                <li>A gentle reminder to follow the rules</li>
                <li>A less gentle reminder to follow the rules</li>
                <li>Temporary or permanent suspension of accounts</li>
                <li>Removal of privileges from, or adding restrictions to, accounts</li>
                <li>Restricting how viewable your content is to other users</li>
                <li>Removal of your content</li>
            </ul>
            <p>If you see any content violating the rules, please email us: <a href="mailto:hello@plantswetter.com">hello@plantswetter.com</a></p>
        </div>
    );
}

import ImageCropper from "./ImageCropper";

const Modal = ({ updateAvatar, closeModal, modalType, boardNumber, newUpdateIncrementer }) => {
  return (
    <div
      className="modal_shell"
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div className="modal_background"></div>
      <div className="modal_inset">
        <div className="modal_layout">
          <div className="modal_spaces">
            <div className="modal_division">
              <button
                type="button"
                className="modal_button"
                onClick={closeModal}
              >
                Close without saving
              </button>
              <ImageCropper
                updateAvatar={updateAvatar}
                closeModal={closeModal}
                modalType={modalType}
                boardNumber={boardNumber}
                newUpdateIncrementer={newUpdateIncrementer}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
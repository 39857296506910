import React from 'react';


export default function Shop(){
    return (
        <div className='shop'>
            <h1>Shop Coming Soon!</h1>
            <h3>This will be a place to buy, sell, trade, or donate plants.</h3>
        </div>
)};

import {useNavigate} from "react-router-dom";
import {useAuth} from '../contexts/AuthContext'
import background from '../images/login_page_photo.jpeg';
import React, {useRef, useState, useEffect} from 'react';
import {doc, getDoc, setDoc} from 'firebase/firestore';
import {db} from '../firebase'

const primaryDesc = '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"Tell us about your plants...","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1,"textFormat":0},{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1,"textFormat":0},{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1,"textFormat":0}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}';
const photoURL = "https://firebasestorage.googleapis.com/v0/b/dev-env-240ae.appspot.com/o/default_avatar.webp?alt=media&token=72396670-b77e-4dfb-bfc4-43e9a3d5f9c4";
const primaryURL = "https://firebasestorage.googleapis.com/v0/b/dev-env-240ae.appspot.com/o/default_primary.webp?alt=media&token=49a1e53d-d932-48c6-bba7-8493ab5f1798";


export default function Signup(){
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const usernameRef = useRef()
    const {signup, login, googleLogIn, resetPassword, currentUser} = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [loginAttempt, setLoginAttempt] = useState('')
    const navigate = useNavigate()

    function handleSignup(e){
        e.preventDefault()
        if (passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError("Whoopsie, your passwords don't match.")
        }
        if (passwordRef.current.value.length < 6){
            return setError("Your passwod needs to have at least six characters.")
        }
        setLoading(false)
        setLoginAttempt('email')
    }

    async function handleLogin(e){
        e.preventDefault()
        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
        } catch {
            setError("Oops, we couldn't log you in, is your info correct?")
        }
        setLoading(false)
    }

    async function handleReset(e){
        e.preventDefault()
        try {
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setError('Password reset email sent.')
        } catch {
            setError("Oops, no reset email sent, is that email address correct?")
        }
        setLoading(false)
    }

    async function handleGoogle(e){
        e.preventDefault()
        try {
            setLoading(true)
            setError('Loading')
            await googleLogIn()
            setLoginAttempt('google')
        } catch (error){
            console.log(error);
            setError("Oops, Google login failed.")
            setLoading(false)
        } 
        setLoading(false)
    }

    function switchToSignup() {
        var formLogin = document.getElementsByClassName("exposed")
        var formCreateAcc = document.getElementsByClassName("hidden")
        for (let i=0;i<formLogin.length;i++){
            formLogin[i].style.cssText += 'display: none;'
        }
        for (let i=0;i<formCreateAcc.length;i++){
            formCreateAcc[i].style.cssText -= 'display: none;'
        }
    }

    function switchToLogin() {
        var formLogin = document.getElementsByClassName("exposed")
        var formCreateAcc = document.getElementsByClassName("hidden")
        var formConsent = document.getElementsByClassName("consent")
        for (let i=0;i<formCreateAcc.length;i++){
            formCreateAcc[i].style.cssText += 'display: none;'
        }
        for (let i=0;i<formConsent.length;i++){
            formConsent[i].style.cssText += 'display: none;'
        }
        for (let i=0;i<formLogin.length;i++){
            formLogin[i].style.cssText -= 'display: none;'
        }
    }

    async function createAccount(e) {
        e.preventDefault()
        try{
            if (loginAttempt==='google'){
                await setDoc(doc(db, 'users', currentUser.uid),{
                    displayName: currentUser.displayName,
                    photoURL,
                    city: "Anytown",
                    state: "Terra Firma",
                    loginType: "google",
                    primaryURL,
                    primaryDesc,
                });
            } else if(loginAttempt==="email"){
                try {
                    setLoading(true)
                    setError('Loading')                    
                    const confirmed = await signup(emailRef.current.value, passwordRef.current.value, passwordConfirmRef.current.value)
                    if (confirmed){
                        await setDoc(doc(db, 'users', confirmed.user.uid),{
                            displayName: usernameRef.current.value,
                            photoURL,
                            city: "Anytown",
                            state: "Terra Firma",
                            loginType: "email",
                            primaryURL,
                            primaryDesc,
                        });
                    };
                } catch(error) {
                    setError("Oops, we couldn't create your account.");
                    console.log(error);
                    setLoading(false)
                }    
            }
            navigate('/dashboard');
        } catch (error){
            console.log(error);
            setError("Account creation error.");
        }
    }

    //Checks to see if a user record exists when a login attempt is made, and if not creates one.
    useEffect(() => {
        //Checks to see if a user record exists, and if not creates one.
        async function handleRedirect(){
            setError('');
            try{
                if (loginAttempt !== ''){
                    //This is where we request consent to privacy policy
                    var formLogin = document.getElementsByClassName("exposed")
                    var formCreateAcc = document.getElementsByClassName("slide-controls")
                    var formForm = document.getElementsByClassName("form")
                    var formConsent = document.getElementsByClassName("consent")
                    for (let i=0;i<formCreateAcc.length;i++){
                        formCreateAcc[i].style.cssText += 'display: none;'
                    }
                    for (let i=0;i<formConsent.length;i++){
                        formConsent[i].style.cssText -= 'display: none;'
                    }
                    for (let i=0;i<formLogin.length;i++){
                        formLogin[i].style.cssText += 'display: none;'
                    }
                    for (let i=0;i<formForm.length;i++){
                        formForm[i].style.cssText += 'display: none;'
                    }
                }    
            } catch (error){
                console.log(error);
                setError("Account creation error.");
            }
        }
        handleRedirect()
      }, [loginAttempt]);

    //Checks to see if a user record exists for someone who's already logged in, and if not creates one.
    useEffect(() => {
        async function handleRedirect(){
            setError('');
            const docRef = doc(db, "users", currentUser.uid);
            const docSnap = await getDoc(docRef);
            try{
                if (!docSnap.exists()){
                    //This is where we request consent to privacy policy
                    var formLogin = document.getElementsByClassName("exposed")
                    var formCreateAcc = document.getElementsByClassName("slide-controls")
                    var formForm = document.getElementsByClassName("form")
                    var formConsent = document.getElementsByClassName("consent")
                    for (let i=0;i<formCreateAcc.length;i++){
                        formCreateAcc[i].style.cssText += 'display: none;'
                    }
                    for (let i=0;i<formConsent.length;i++){
                        formConsent[i].style.cssText -= 'display: none;'
                    }
                    for (let i=0;i<formLogin.length;i++){
                        formLogin[i].style.cssText += 'display: none;'
                    }
                    for (let i=0;i<formForm.length;i++){
                        formForm[i].style.cssText += 'display: none;'
                    }
                } else{navigate('/dashboard');}       
            } catch (error){
                console.log(error);
                setError("Account creation error.");
            }
        }
        // redirects a user if they're alredy logged in.
        currentUser ? handleRedirect() : console.log("Waiting for login...")
      }, [currentUser]);

    useEffect(() => {
        switchToLogin();
    },[]);

    const [codeAccepted, setCodeAccepted] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [continueDisabled, setContinueDisabled] = useState(true);

    const handleCodeCheck = event => {
        setCodeAccepted(current => !current)
    }

    const handleTermsCheck = event => {
        setTermsAccepted(current => !current)
    }

    useEffect(() => {
        if (codeAccepted===true && termsAccepted===true){
            setContinueDisabled(false)
        }
    },[codeAccepted, termsAccepted]);

    return (
        <div className="log-in-sign-up">
            <div className="picture" style={{backgroundImage: `url(${background})`}}>
            </div>
            <div className="right" >
                {error && <div className="signup-alert-box">{error}</div>}
                <div className="slide-controls">
                    <input type="radio" name="slide" id="login" defaultChecked onClick={switchToLogin}/>
                    <input type="radio" name="slide" id="signup" onClick={switchToSignup}/>
                    <label htmlFor="login" className="slide login">Login</label>
                    <label htmlFor="signup" className="slide signup">Signup</label>
                    <div className="slider-tab"></div>
                </div>
                <form>
                    <div className="form">
                        <div className="input">
                            <label htmlFor="email">Email</label>
                            <input type="email" name="email" id="email" required="" autoComplete="email" ref={emailRef}/>
                        </div>

                        <div className="input hidden">
                            <label htmlFor="user-name">Display name</label>
                            <input type="text" name="user-name" id="user-name" required="" autoComplete="username" ref={usernameRef}/>
                        </div>
                
                        <div className="input">
                            <label htmlFor="password">Password</label>
                            <input type="password" name="password" id="password" required="" autoComplete="current-password" ref={passwordRef}/>
                        </div>
                        
                        <div className="input hidden">
                            <label htmlFor="confirm-password">Confirm password</label>
                            <input type="password" name="confirm-password" id="confirm-password" required="" autoComplete="new-password" ref={passwordConfirmRef}/>
                        </div>
                        <button type="submit" className="exposed" disabled={loading} onClick={handleLogin}>Log In</button>
                        <button type="submit" className="hidden" disabled={loading} onClick={handleSignup}>Sign Up</button>
                        <button type="submit" className="exposed" disabled={loading} onClick={handleReset}>Forgot Password?</button>
                        
                    </div>
                </form>  
                <h2 className="or exposed">- OR -</h2>
                <button type="submit" className="exposed" disabled={loading} onClick={handleGoogle}>Log In with Google</button>            
                <form>
                    <div className="consent">
                        <div className="consent-checkbox">
                            <input 
                                type="checkbox"
                                value={codeAccepted}
                                onChange={handleCodeCheck}
                                id="code"
                                name="code"
                            />
                            <label>I agree to the <a href="/conduct">Code of Conduct</a>.</label>
                        </div>
                        <div className="consent-checkbox">
                            <input 
                                type="checkbox"
                                value={termsAccepted}
                                onChange={handleTermsCheck}
                                id="terms"
                                name="terms"
                            />
                            <label>I accept the <a href='/terms'>Terms & Conditions</a> and <a href="/privacy">Privacy Policy</a>.</label>
                        </div>              
                        <button type="submit" disabled={continueDisabled} onClick={createAccount}>Continue</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

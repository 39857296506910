import React, {useState, useRef, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { doc, getDoc, collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { db } from '../firebase'
import Entry from './Entry.jsx'
import ForwardIcon from "./icons/ForwardIcon.jsx";
import BackIcon from "./icons/BackIcon.jsx";


//This JournalView page displays what a non-user sees when then browsing a user's page.
//For the page that a logged in user sees when viewing their journal page, please see JournalEdit.jsx.

export default function JournalView(){
    const params = useParams();
    const journalId = params.userId;
    
    const primaryUrl = useRef('');
    const [error, setError] = useState('');
    const [boardPicture, setBoardPicture] = useState('')

    const [userProfile, setUserProfile] = useState([]);
    useEffect(()=> {
        const userProfileRef = doc(db, "users", journalId);
        const getUserProfile = async () => {
            try {
                const data = await getDoc(userProfileRef);
                setUserProfile(data.data());
                primaryUrl.current = data.data().primaryURL;
                setBoardPicture(data.data().primaryURL)
            } catch(err){
                console.error(err);
                setError("Could not find this user's journal :(")
            }
        };
        getUserProfile();
    }, [journalId]);

    const [allUpdates, setAllUpdates] = useState([]);
    const updateList = collection(db, "updates");
    const [updateNumber, setUpdateNumber] = useState(0);

    useEffect(() => {
        let updates = [];
        
        const queryUpdates = query(
            updateList,
            where("user", "==", journalId),
            orderBy("createdAt", "desc"),
        );
        const fetchUpdates = async () => {
            let data = await getDocs(queryUpdates);
            data.forEach((doc) => {
                updates.push({ID: doc.id, ...doc.data() });
            })
            setAllUpdates(updates)
        };
        fetchUpdates();
        setUpdateNumber(0);
    },[]);

    function moveBackward() {
        if (updateNumber === 0) {
            setUpdateNumber(allUpdates.length-1)
        } else {
            setUpdateNumber(updateNumber - 1)
        }
    }

    function moveForward() {
        if (updateNumber === allUpdates.length-1) {
            setUpdateNumber(0);
        } else {
            setUpdateNumber(updateNumber + 1)
        }
    }

    function UpdateViewer() {
        if (allUpdates.length === 0) {
            return <h1>No updates have been added to this journal yet.</h1> 
        } else {
            return <div>
                <div className='update-nav'>
                    {allUpdates.length>1 ? <button onClick={moveBackward}><BackIcon width='34px'/></button> : null }
                    <h2>Update:<br/>
                    {new Date(allUpdates[updateNumber].createdAt.seconds*1000).toLocaleDateString()}</h2>
                    {allUpdates.length>1 ? <button onClick={moveForward}><ForwardIcon width='34px'/></button> : null}
                </div>

                <Entry
                    boardOwner={journalId}
                    boardNumber={allUpdates[updateNumber].ID}
                    boardPicture={allUpdates[updateNumber].photoURL}
                    entryCollection="updates"
                    viewerType='reader'
                />
            </div>
        };

    };

    return boardPicture ? (
        <div className="journal">
            <h1>{userProfile.displayName}'s collection overview: </h1>
            {error && <div className="signup-alert-box">{error}</div>}
            <br />

            <Entry 
                boardOwner={journalId}
                boardNumber={journalId}
                boardPicture={boardPicture}
                entryCollection="users"
                viewerType='reader'
            />

            <UpdateViewer />

        </div>
    ) : <div className="journal"><div className="alert-box">{error}</div></div>
};

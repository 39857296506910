import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { db } from '../../firebase';
import {useAuth} from '../../contexts/AuthContext';
import { doc, getDoc, setDoc } from "firebase/firestore";

import ExampleTheme from './ExampleTheme.ts';
import ToolbarPlugin from './ToolbarPlugin.tsx';
import React, { useState, useEffect } from 'react';
import PencilIcon from "../icons/PencilIcon";
import SaveIcon from "../icons/SaveIcon";
// import TreeViewPlugin from './TreeViewPlugin.tsx';

function Placeholder() {
  return <div className="editor-placeholder">Tell us about your plants...</div>;
}


export default function TextEditor({readerLookup, entryCollection, viewerType}) {
  const [editorState, setEditorState] = useState<string>('{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"Tell us about your plants...","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1,"textFormat":0}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}');
  const [readerState, setReaderState] = useState('{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"Tell us about your plants...","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1,"textFormat":0}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}');
  const [editability, setEditability] = useState(false);
  // const {currentUser} = useAuth();
  const [needStateUpdate, setNeedStateUpdate] = useState(false);

  const editorConfig = {
    namespace: 'Editor',
    nodes: [],
    // Handling of errors during update
    onError(error: Error) {
      throw error;
    },
    // The editor theme
    theme: ExampleTheme,
  };

  const readerConfig = {
    namespace: 'Reader',
    nodes: [],
    // Handling of errors during update
    onError(error: Error) {
      throw error;
    },
    // The editor theme
    theme: ExampleTheme,
    editable: false,
  };

  function UpdateReader() {
    const [editor] = useLexicalComposerContext();
    useEffect(()=> {
      editor.setEditorState(editor.parseEditorState(readerState));
    },[editor])
    return <></>;
  }

  function UpdateEditor() {
    const [editor] = useLexicalComposerContext();
    useEffect(()=> {
      if (needStateUpdate) {
        editor.setEditorState(editor.parseEditorState(editorState));
        editor.focus()
        setNeedStateUpdate(false);
      };
    },[])
    return null;
  }

  function editButton(){
    setEditability(true);
    setNeedStateUpdate(true);
  }

  function onChange(editorState) {
    const editorStateJSON = editorState.toJSON();
    setEditorState(JSON.stringify(editorStateJSON));
  }

  async function saveButton() {
      setReaderState(editorState);
      try{
        await setDoc(doc(db, entryCollection, readerLookup), {primaryDesc: `${editorState}`}, {merge:true})
      } catch(err){
        console.error(err);
      }
      setEditability(false);
  }

  useEffect(()=> {
    const userProfileRef = doc(db, entryCollection, readerLookup);
    const getUserProfile = async () => {
        try {
            const data = await getDoc(userProfileRef);
            setReaderState(data.data()?.primaryDesc);
            setEditorState(data.data()?.primaryDesc);
        } catch(err){
            console.error(err);
        }
    };
    getUserProfile();
}, [readerLookup]);

  return (
    <div className="text-editor">
      <LexicalComposer initialConfig={editorConfig}>
        <div className="editor-container" hidden={!editability}>
          <ToolbarPlugin />
          <div className="editor-inner" >
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <OnChangePlugin onChange={onChange} />
            <UpdateEditor />
            {/* <TreeViewPlugin /> */}
          </div>
        </div>
      </LexicalComposer>
      <button
        hidden={!editability}
        className='text-edit-button'
        title='Update overview description'
        onClick={() => saveButton()}
        ><SaveIcon width="34px"/>
      </button>

      <LexicalComposer initialConfig={readerConfig}>
        <div className="editor-container" hidden={editability}>
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <UpdateReader />
          </div>
        </div>
      </LexicalComposer>
      {viewerType==='writer' && <button
        hidden={editability}
        className='text-edit-button'
        title='Update overview description'
        onClick={() => editButton()}
        > <PencilIcon width="34px"/>
      </button>}
      <div className="editor-foot"></div>
    </div>
  );
}

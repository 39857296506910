import React from 'react';

export default function Activity(){
    const border = {borderRadius: "7px 7px 0px 0px"};

    return (
        <div className='activity'>
            <h2 style={border}>Messages</h2>
            <div className='message-space'>Coming soon!</div>
            <h2>NewsFeed</h2>
            <div className='message-space'>Coming soon!</div>
        </div>
    )
}
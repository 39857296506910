import React from 'react';
import {Navigate} from "react-router-dom";
import SearchResults from './SearchResults';
import {useAuth} from '../contexts/AuthContext';


export default function Browse(){
    const {currentUser} = useAuth()

    return currentUser ? (
        <SearchResults />
    ) : <Navigate to="/signup" />; 
};
